// src/components/Footer.js
import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <footer style={{
            width: '100%',
            backgroundColor: '#f1f1f1',
            textAlign: 'center',
            padding: '10px 0',
        }}>
            <Box>
                <Typography variant="body2" color="textSecondary">
                    Powered by{' '}
                    <Link
                        href="https://www.chaosology.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                        sx={{ color: "#1E2548", fontWeight: "bold" }}
                    >
                        Chaosology
                    </Link>
                </Typography>
            </Box>
        </footer>
    );
};

export default Footer;
