import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "./Layout.css"; // Import the CSS file

const Layout = () => {
  return (
    <div className="layout">
      {/* Navbar for all devices (fixed for mobile) */}
      <div className="navbar">
        <Navbar />
      </div>

      <div className="main-container">
        {/* Sidebar - Hidden on small screens */}
        <aside className="sidebar">
          <Sidebar />
        </aside>

        {/* Main Content */}
        <main className="content">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
