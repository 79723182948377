import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper, Container, Avatar } from '@mui/material';
import Logo from "./assets/Magnolia-Logo-T.png";
import swal from 'sweetalert';
import { useLocation, useNavigate } from 'react-router-dom';

const SignIn = () => {

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const handleNameChange = (e) => {
        setUsername(e.target.value);
        if (!e.target.value) {
            setUsernameError("Username is required");
        } else {
            setUsernameError("");
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (!e.target.value) {
            setPasswordError("Password is required");
        } else {
            setPasswordError("");
        }
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (!username) {
            setUsernameError("Username is required");
        } else {
            setUsernameError("");
        }

        if (!password) {
            setPasswordError("Password is required");
        } else {
            setPasswordError("");
        }

        if (username && password) {
            let signinObject = { username: username, password: password }
            const url = process.env.REACT_APP_SERVER_URL + "/captivekings/auth/signin";
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer ' + user.accessToken
                },
                body: JSON.stringify(signinObject)
            };
            try {
                const response = await fetch(url, config);
                const data = await response.json();
                console.log("data" + data);
                if (data.accessToken) {
                    sessionStorage.setItem("user", JSON.stringify(data));
                    sessionStorage.setItem("userId", JSON.stringify(data.id));
                    sessionStorage.setItem("userName", JSON.stringify(data.username));
                    console.log("data" + data);
                    navigate("/dashboard");
                }  else if (response.status === 401) {
                    swal("Username or Password Wrong");
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }

    return (
        <>

            <Container
                maxWidth="md"
                component="main"
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 0, // No margin to fully center it
                    mb: 0,
                    px: { xs: 2, sm: 4 }
                }}
            >

                <Paper
                    elevation={3}
                    sx={{
                        padding: 4,
                        borderRadius: 2,
                        textAlign: 'center',
                        backgroundColor: '#f5f5f5',
                        width: '100%',
                        maxWidth: '400px'
                    }}
                >
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <img src={Logo} alt="Logo" style={{ width: "70%", height: "auto" }} />
                    </Box><br />
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <Typography variant="h4" gutterBottom sx={{ color: '#1E2548', fontWeight: 'bold' }}>
                            Sign In
                        </Typography>  </Box>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label={<span>Username <span style={{ color: "#1E2548" }}>*</span></span>}
                            variant="outlined"
                            margin="normal"
                            value={username}
                            onChange={handleNameChange}
                            error={usernameError}
                            helperText={usernameError}
                        />
                        <TextField
                            fullWidth
                            label={<span>Password <span style={{ color: "#1E2548" }}>*</span></span>}
                            type="password"
                            variant="outlined"
                            margin="normal"
                            value={password}
                            onChange={handlePasswordChange}
                            error={passwordError}
                            helperText={passwordError}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{
                                mt: 3,
                                mb: 2,
                                backgroundColor: '#1E2548',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#141A33',
                                }
                            }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </>
    );
};

export default SignIn;
