// src/components/Navbar.jsx
import React, { useState, useRef, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Avatar, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

const primaryColor = '#1E2548';
const secondaryColor = '#FFDBBB';

const Navbar = ({ handleDrawerToggle }) => {
    const [showLogout, setShowLogout] = useState(false);
    const wrapperRef = useRef(null);

    const handleAvatarClick = () => {
        setShowLogout((prev) => !prev);
    };

    const userName = JSON.parse(sessionStorage.getItem("userName"));
    console.log(userName, "userName");

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowLogout(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        window.location.href = '/login';
    };

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { sm: `calc(100% - 250px)` },
                ml: { sm: `250px` },
                backgroundColor: primaryColor
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' } }}>
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap sx={{ flexGrow: 1, color: secondaryColor }}>
                    The Magnolia Agency
                </Typography>
                <Box ref={wrapperRef} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ mr: 2, color: secondaryColor }}>
                        {userName?.charAt(0).toUpperCase() + userName?.slice(1).toLowerCase()}
                    </Typography>
                    <Avatar
                        alt="userName"
                        sx={{
                            color: primaryColor,
                            cursor: 'pointer',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                        onClick={handleAvatarClick}
                    >  {userName?.charAt(0).toUpperCase()} </Avatar>
                    {showLogout && (
                        <Box
                            sx={{
                                position: 'absolute',
                                right: 10,
                                mt: 15,
                                bgcolor: primaryColor,
                                boxShadow: 3,
                                borderRadius: 1,
                                zIndex: 1
                            }}
                        >
                            <Button
                                onClick={handleLogout}
                                startIcon={<LogoutIcon />}
                                sx={{
                                    color: secondaryColor,
                                    textTransform: 'none'
                                }}
                            >
                                Logout
                            </Button>
                        </Box>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
