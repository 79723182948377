// src/pages/Dashboard.jsx
import React, { useState } from 'react';
import { Box, Toolbar, Typography, Grid, Card, CardContent, Paper, useMediaQuery } from '@mui/material';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList, FunnelChart, Funnel, LineChart, Line, PieChart, Pie, Cell, Legend, ComposedChart, Area, AreaChart, CartesianGrid } from 'recharts';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 250;

const Dashboard = () => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const Data = [
        { year: '2020', value: 38 },
        { year: '2021', value: 45 },
        { year: '2022', value: 54 },
        { year: '2023', value: 63 },
        { year: '2024', value: 75 },
    ];

    const colors = [' #8884d8', ' #82ca9d', 'rgb(163, 209, 199)', ' #ff7f50', 'rgb(235, 231, 182)'];
    const funnelData = [
        { name: 'Prospects', value: 320 },
        { name: 'Quote Requests', value: 240 },
        { name: 'Pending Quotes', value: 150 },
        { name: 'Approved Quotes', value: 90 },
        { name: 'Declined Quotes', value: 40 },
    ];

    const premiumData = [
        { month: 'Jan', revenue: 50000 },
        { month: 'Feb', revenue: 60000 },
        { month: 'Mar', revenue: 70000 },
        { month: 'Apr', revenue: 80000 },
        { month: 'May', revenue: 95000 },
    ];

    const approvalData = [
        { name: 'Approved', value: 70 },
        { name: 'Declined', value: 30 },
    ];

    const sourceData = [
        { name: 'Organic Website Visitors', value: 50 },
        { name: 'Referral', value: 30 },
        { name: 'Paid Ads', value: 10 },
        { name: 'Email Marketing', value: 5 },
        { name: 'Cold Calls', value: 5 },
    ];

    const agentPerformanceData = [
        { agent: 'Agent A', policiesSold: 50, premiumGenerated: 200000 },
        { agent: 'Agent B', policiesSold: 70, premiumGenerated: 250000 },
        { agent: 'Agent C', policiesSold: 30, premiumGenerated: 100000 },
    ];

    const claimsData = [
        { timePeriod: 'Jan', claimsFiled: 200, claimsSettled: 180 },
        { timePeriod: 'Feb', claimsFiled: 220, claimsSettled: 200 },
        { timePeriod: 'Mar', claimsFiled: 250, claimsSettled: 230 },
    ];

    const leadResponseData = [
        { timePeriod: 'Week 1', responseTime: 1.5 },
        { timePeriod: 'Week 2', responseTime: 2.5 },
        { timePeriod: 'Week 3', responseTime: 3.5 },
    ];

    const getCategory = (time) => {
        if (time < 1) return 'Excellent';
        if (time >= 1 && time <= 6) return 'Good';
        if (time > 6 && time <= 24) return 'Needs Improvement';
        return 'Poor';
    };

    // Function to get color based on category
    const getColor = (category) => {
        switch (category) {
            case 'Excellent':
                return 'green';
            case 'Good':
                return 'orange';
            case 'Needs Improvement':
                return 'yellow';
            case 'Poor':
                return 'red';
            default:
                return 'gray';
        }
    };

    const pendingProcessedData = [
        { timePeriod: 'Week 1', pending: 40, processed: 60 },
        { timePeriod: 'Week 2', pending: 50, processed: 70 },
        { timePeriod: 'Week 3', pending: 30, processed: 80 },
    ];

    const revenueBreakdownData = [
        { policyType: 'Auto', revenue: 100000 },
        { policyType: 'Home', revenue: 150000 },
        { policyType: 'Life', revenue: 200000 },
        { policyType: 'Health', revenue: 120000 },
    ];

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div 
        sx={{

            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 2,
            mb: 2,
            width: isMobile ? '100%' : 'calc(100% - 240px)',
            transition: 'margin 0.3s ease-in-out, width 0.3s ease-in-out',
            marginLeft: isMobile ? '0' : '0px',
            // padding: isIpadMini ? '0 20px' : '0 32px', // Adjust padding for iPad Mini
        }}>
            <Navbar handleDrawerToggle={handleDrawerToggle} />
            <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />

            <Grid container spacing={2} >
                {/* Prospects and Quotes */}
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Prospects</Typography>
                        <Card item xs={12} sm={6} md={4} sx={{ height: 100, mb: 2, background: 'linear-gradient(45deg,rgb(90, 173, 92),rgb(133, 194, 136))', boxShadow: 2 }}>
                            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography sx={{ textAlign: 'center', mb: 4, }}>Prospects: 2</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ height: 100, mb: 2, background: 'linear-gradient(45deg,rgb(137, 115, 170),rgb(144, 125, 177))', boxShadow: 2 }}>
                            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography sx={{ textAlign: 'center', mb: 4, }}>Quote Requests: 3</Typography>
                            </CardContent>
                        </Card>
                        <Card sx={{ height: 100, mb: 2, background: 'linear-gradient(45deg, #FFC107, #FFEB3B)', boxShadow: 2 }}>
                            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography sx={{ textAlign: 'center', mb: 4, }}>Pending Quotes: 2</Typography>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>



                {/* Customers: Active & New */}
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>Customers</Typography>
                        <Card sx={{ height: 250, mb: 2, background: 'linear-gradient(45deg, #03A9F4, #4FC3F7)', boxShadow: 2 }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography sx={{ textAlign: 'center', mb: 2 }}>Active Customers</Typography>
                                <ResponsiveContainer width="100%" height={120}>
                                    <BarChart data={Data}>
                                        <XAxis dataKey="year" stroke="black" />
                                        <YAxis dataKey="value" stroke="black" />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <Bar dataKey="value" fill="rgb(198, 197, 206)" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>

                        <Card sx={{ height: 250, mb: 2, background: 'linear-gradient(45deg,rgb(221, 191, 146),rgb(248, 192, 26))' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Typography sx={{ textAlign: 'center', mb: 2 }}>New Customers</Typography>
                                <ResponsiveContainer width="100%" height={120}>
                                    <BarChart data={Data}>
                                        <XAxis dataKey="year" stroke="black" />
                                        <YAxis stroke="black" />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <Bar dataKey="value" fill="rgb(197, 197, 201)" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
                {/* Funnel Chart & Premium Growth Trend */}
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">
                            Quote Conversion Funnel
                        </Typography>
                        <ResponsiveContainer width="100%" height={250} style={{ marginTop: '20px' }}>
                            <FunnelChart>
                                <Tooltip />
                                <Funnel dataKey="value" data={funnelData.map((entry, index) => ({ ...entry, fill: colors[index % colors.length] }))} isAnimationActive stroke=" rgb(76, 81, 83)" >
                                    <LabelList position="right" dataKey="name" style={{ fontSize: "13px", fill: "white" }} />
                                </Funnel>
                            </FunnelChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                {/* <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Quote Conversion Funnel</Typography>
                        <ResponsiveContainer width="110%" height={250}>
                            <FunnelChart margin={{ right: 30 }}>
                                <Tooltip formatter={(value, name) => [`${value}`, `${name}`]} />
                                <Funnel
                                    dataKey="value"
                                    data={funnelData}
                                    isAnimationActive
                                    stroke="grey"  // Changed stroke color to dark grey
                                    strokeWidth={2}
                                >
                                    {funnelData.map((entry, index) => (
                                        <LabelList
                                            key={index}
                                            position="right"
                                            dataKey="name"
                                            offset={5}
                                            style={{
                                                fontSize: '12px',
                                                fill: 'orange',
                                                fontFamily: 'Lato'
                                            }}
                                        />
                                    ))}
                                    {funnelData.map((entry, index) => (
                                        <path
                                            key={index}
                                            d={`M0,${index * 60} L100,${index * 60} L80,${(index + 1) * 60} L20,${(index + 1) * 60} Z`}
                                            fill={funnelColors[index]}
                                            stroke="darkgrey"
                                            strokeWidth={2}
                                        />
                                    ))}
                                </Funnel>
                            </FunnelChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid> */}




                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Premium Growth Trend</Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={premiumData}>
                                <XAxis dataKey="month" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                {/* Policy Approval vs. Decline Rate & Customer Acquisition Sources */}
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Policy Approval vs Decline Rate</Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <PieChart>
                                <Pie
                                    data={approvalData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    label
                                >
                                    {approvalData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={index === 0 ? 'skyblue' : 'orange'} />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Customer Acquisition Sources</Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <PieChart>
                                <Pie
                                    data={sourceData}
                                    dataKey="value"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={80}
                                    fill="#8884d8"
                                    label
                                >
                                    {sourceData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={['rgb(174, 145, 207)', 'rgb(218, 201, 126)', 'rgb(179, 231, 179)', 'rgb(226, 136, 186)', 'rgb(127, 197, 188)'][index]}
                                        />
                                    ))}
                                </Pie>
                                <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                {/* Agent Performance Overview & Claims Ratio & Settlement Time */}
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Agent Performance Overview</Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <BarChart data={agentPerformanceData}>
                                <XAxis dataKey="agent" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="policiesSold" fill="#8884d8" />
                                <Bar dataKey="premiumGenerated" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Claims Ratio & Settlement Time</Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <ComposedChart data={claimsData}>
                                <XAxis dataKey="timePeriod" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="claimsFiled" fill="#8884d8" />
                                <Bar dataKey="claimsSettled" fill="#82ca9d" />
                                <Area type="monotone" dataKey="claimsFiled" fill="#8884d8" stroke="#8884d8" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                {/* Lead Response Time Analysis & Pending vs Processed Policies */}
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Lead Response Time Analysis</Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={leadResponseData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="timePeriod" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="responseTime"
                                    stroke="#8884d8"
                                    dot={(props) => {
                                        const category = getCategory(props.payload.responseTime);
                                        return <Cell fill={getColor(category)} />;
                                    }}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Pending vs Processed Policies</Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <BarChart data={pendingProcessedData}>
                                <XAxis dataKey="timePeriod" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pending" stackId="a" fill="#8884d8" />
                                <Bar dataKey="processed" stackId="a" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                {/* Revenue Breakdown by Policy Type */}
                <Grid item xs={12} sm={6} md={4}>
                    <Paper style={{ padding: '16px', height: '100%' }}>
                        <Typography variant="h6" align="center">Revenue Breakdown by Policy Type</Typography>
                        <ResponsiveContainer width="100%" height={250}>
                            <BarChart data={revenueBreakdownData}>
                                <XAxis dataKey="policyType" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="revenue" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
            </Grid>
        </div>


    );
};

export default Dashboard;
