import React, { useEffect, useState } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    TextField,
    Button,
    useMediaQuery,
} from '@mui/material';
import { SaveAlt as DownloadIcon } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const InsuranceData = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [cusData, setCusData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterText, setFilterText] = useState('');
    console.log(filteredData, "filteredData")
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isIpadMini = useMediaQuery('(min-width: 768px) and (max-width: 834px)');

    useEffect(() => {
        const fetchInsDetails = async () => {
            const user = JSON.parse(sessionStorage.getItem("user"));
            const url = process.env.REACT_APP_SERVER_URL + "/captivekings/customerinsurancedetails";
            const config = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.accessToken
                },
            };
            try {
                const response = await fetch(url, config);
                if (response.ok) {
                    const fetchedData = await response.json();
                    setData(fetchedData);
                    // setFilteredData(fetchedData);
                    console.log("Fetched insuranceDetails:", fetchedData); // Debugging fetched data
                } else {
                    console.error('Failed to fetch insurance details');
                }
            } catch (error) {
                console.error('Error fetching insurance details:', error);
            }
        }
        fetchInsDetails();
    }, []);

    useEffect(() => {
        const fetchCusDetails = async () => {
            const user = JSON.parse(sessionStorage.getItem("user"));
            const url = process.env.REACT_APP_SERVER_URL + "/captivekings/allcustomerinfo";
            const config = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.accessToken
                },
            };
            try {
                const response = await fetch(url, config);
                if (response.ok) {
                    const fetchedData = await response.json();
                    setCusData(fetchedData);
                    setFilteredData(fetchedData);
                    console.log("Fetched insuranceDetails:", fetchedData); // Debugging fetched data
                } else {
                    console.error('Failed to fetch insurance details');
                }
            } catch (error) {
                console.error('Error fetching insurance details:', error);
            }
        }
        fetchCusDetails();
    }, []);

    // Handle Pagination Change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle Filter Change
    const handleFilterChange = (event) => {
        setFilterText(event.target.value);
        const filtered = cusData.filter((item) =>
            item?.firstName?.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setFilteredData(filtered);
    };

    const downloadExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('5 Year Look back Data');

        // Extract unique years from data
        const uniqueYears = [...new Set(data.map(row => row.year))].sort();

        // Define dynamic headers
        const headers = [
            { header: 'Company', key: 'Company', width: 20 },
            { header: 'First Name', key: 'FirstName', width: 20 },
            { header: 'Last Name', key: 'LastName', width: 20 },
            { header: 'Email', key: 'Email', width: 30 },
            { header: 'Phone', key: 'Phone', width: 15 },
        ];

        // Add dynamic year-based headers
        uniqueYears.forEach(year => {
            headers.push({ header: `Premium ${year}`, key: `Premium_${year}`, width: 15 });
            headers.push({ header: `Claims ${year}`, key: `Claims_${year}`, width: 15 });
            headers.push({ header: `Loss Funds ${year}`, key: `LossFunds_${year}`, width: 15 });
            headers.push({ header: `Auto Losses ${year}`, key: `AutoLosses_${year}`, width: 15 });
            headers.push({ header: `GL Losses ${year}`, key: `GlLosses_${year}`, width: 15 });
            headers.push({ header: `WC Losses ${year}`, key: `WcLosses_${year}`, width: 15 });
            headers.push({ header: `Total Losses ${year}`, key: `TotalLosses_${year}`, width: 15 });
            headers.push({ header: `Equity ${year}`, key: `Equity_${year}`, width: 15 });
            headers.push({ header: `Net Insurance Spend ${year}`, key: `NetInsuranceSpend_${year}`, width: 20 });
        });

        // Add total headers (Directly from DB)
        headers.push({ header: 'Premium Total', key: 'PremiumTotal', width: 15 });
        headers.push({ header: 'Auto Losses Total', key: 'AutoLossesTotal', width: 15 });
        headers.push({ header: 'Loss Funds Total', key: 'LossFundsTotal', width: 15 });
        headers.push({ header: 'GL Losses Total', key: 'GlLossesTotal', width: 15 });
        headers.push({ header: 'WC Losses Total', key: 'WcLossesTotal', width: 15 });
        headers.push({ header: 'Total Losses Total', key: 'TotalOFLossesTotal', width: 15 });
        headers.push({ header: 'Equity Total', key: 'EquityTotal', width: 15 });

        worksheet.columns = headers;

        // Group data by customer
        const groupedData = {};
        data.forEach(row => {
            const key = `${row.company}_${row.firstName}_${row.lastName}_${row.email}_${row.phone}`;
            if (!groupedData[key]) {
                groupedData[key] = {
                    Company: row.company,
                    FirstName: row.firstName,
                    LastName: row.lastName,
                    Email: row.email,
                    Phone: row.phone,

                    // Directly assign totals from DB
                    PremiumTotal: row.premiumTotal,
                    AutoLossesTotal: row.autoLossesTotal,
                    LossFundsTotal: row.lossFundsTotal,
                    GlLossesTotal: row.glLossesTotal,
                    WcLossesTotal: row.wcLossesTotal,
                    TotalOFLossesTotal: row.totalLossesTotal,
                    EquityTotal: row.equityTotal,
                };
            }

            groupedData[key][`Premium_${row.year}`] = row.premium;
            groupedData[key][`Claims_${row.year}`] = row.claims;
            groupedData[key][`LossFunds_${row.year}`] = row.lossFunds;
            groupedData[key][`AutoLosses_${row.year}`] = row.autoLosses;
            groupedData[key][`GlLosses_${row.year}`] = row.glLosses;
            groupedData[key][`WcLosses_${row.year}`] = row.wcLosses;
            groupedData[key][`TotalLosses_${row.year}`] = row.totalLosses;
            groupedData[key][`Equity_${row.year}`] = row.equity;
            groupedData[key][`NetInsuranceSpend_${row.year}`] = row.netInsuranceSpend;
        });

        // Add data rows
        Object.values(groupedData).forEach(row => {
            worksheet.addRow(row);
        });

        // Style Headers
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = {
                name: 'Lato',
                color: { argb: 'FFDBBB' },
                bold: true,
            };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '1E2548' }
            };
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        // Generate Excel File
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, '5 Year Look back Data.xlsx');
        });
    };

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (

        <Box sx={{ p: 2, maxWidth: '100%', mt: 20 }}>
            <Navbar handleDrawerToggle={handleDrawerToggle} />
            <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    gap: 2,
                    mb: 2,
                    width: isMobile ? '100%' : 'calc(100% - 240px)',
                    transition: 'margin 0.3s ease-in-out, width 0.3s ease-in-out',
                    marginLeft: isMobile ? '0' : '0px',
                    // padding: isIpadMini ? '0 20px' : '0 32px', // Adjust padding for iPad Mini
                }}
            >
                <TextField
                    label="Search"
                    variant="outlined"
                    value={filterText}
                    onChange={handleFilterChange}
                    fullWidth={isMobile}  // Make full width on mobile
                    sx={{ flex: isMobile ? '1 1 100%' : '1 1 auto' }}
                />
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#1E2548',
                        color: '#FFDBBB',
                        fullWidth: { isMobile }  // Full width on mobile
                    }}
                    startIcon={<DownloadIcon />}
                    onClick={downloadExcel}
                >
                    Download
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{ overflowX: 'auto', marginLeft: isMobile ? '60' : '0px', padding: isMobile ? '0 16px' : '0' }}>
                <Table >
                    <TableHead sx={{ backgroundColor: '#1E2548' }}>
                        <TableRow>
                            <TableCell sx={{ color: '#FFDBBB', whiteSpace: 'nowrap' }}>Company</TableCell>
                            <TableCell sx={{ color: '#FFDBBB', whiteSpace: 'nowrap' }}>First Name</TableCell>
                            <TableCell sx={{ color: '#FFDBBB', whiteSpace: 'nowrap' }}>Last Name</TableCell>
                            <TableCell sx={{ color: '#FFDBBB', whiteSpace: 'nowrap' }}>Email</TableCell>
                            <TableCell sx={{ color: '#FFDBBB', whiteSpace: 'nowrap' }}>Phone</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredData
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.company}</TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.firstName}</TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.lastName}</TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {row.email}
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: 'nowrap' }}>{row.phone}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
    );
};

export default InsuranceData;
