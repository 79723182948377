import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import InsuranceForm from "./InsuranceForm";
import SignIn from "./Signin";
import Dashboard from "./Dashboard";
import InsuranceData from "./InsuranceData";
import Layout from './Layouts';
import Footer from './Footer';
import { Box } from '@mui/material';

function App() {
  return (
    <div className="App">
      <div className="container mt-4">
        <Router>
          <Routes>
            {/* Direct route for Login WITHOUT Layout */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/" element={<InsuranceForm />} />

            {/* All other routes under Layout */}
            <Route path="/" element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/5-year-look-back" element={<InsuranceData />} />
            </Route>

            {/* Redirect any unknown routes to the Login Page */}
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '100vh', // Make sure the page takes full height
              overflow: 'hidden',  
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              {/* Your page content here */}
            </Box>
            <Footer />
          </Box>
        </Router>


        {/* <Signin /> */}
      </div>
    </div>
  );
}

export default App;
