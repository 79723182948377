import React, { useState, useEffect, useRef } from "react";
import { Container, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Typography, Box, TableContainer, Paper, Grid, Link } from "@mui/material";
import Logo from "./assets/logo.png";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const InsuranceForm = () => {
    const currentYear = new Date().getFullYear();
    const lastFiveYears = Array.from({ length: 5 }, (_, i) => `${currentYear - i - 1}-${currentYear - i}`).reverse();

    const [formData, setFormData] = useState({
        fname: "",
        lname: "",
        email: "",
        phone: "",
        company: "",
        premiumClaims: lastFiveYears.reduce((acc, year) => {
            acc[year] = { premium: "", claims: "" };
            return acc;
        }, {}),
    });

    const [fnameError, setFNameError] = useState(null);
    const [lnameError, setLNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [companyError, setCompanyError] = useState(null);
    const [calculatedData, setCalculatedData] = useState(null);

    let calculateGetData = null;
    const handleCompanyChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setCompanyError("Company is required");
        } else {
            setCompanyError("");
        }
    };

    const handleFNameChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setFNameError("First Name is required");
        } else {
            setFNameError("");
        }
    };

    const handleLNameChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (!e.target.value) {
            setLNameError("Last Name is required");
        } else {
            setLNameError("");
        }
    };

    const handleEmailChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  // Basic email pattern
        const email = e.target.value;
        if (!email) {
            setEmailError("Email is required");
        } else if (!emailRegex.test(email)) {
            setEmailError("Enter a valid email address");
        } else {
            setEmailError("");
        }
    };

    const handlePhoneChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        const phoneRegex = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
        const phone = e.target.value;
        if (!phone) {
            setPhoneError("Phone is required");
        } else if (!phoneRegex.test(phone)) {
            setPhoneError("Enter a valid phone number");
        } else {
            setPhoneError("");
        }
    };

    const [tableErrors, setTableErrors] = useState({});
    // const handleTableChange = (year, field, value) => {
    //     setFormData({
    //         ...formData,
    //         premiumClaims: {
    //             ...formData.premiumClaims,
    //             [year]: { ...formData.premiumClaims[year], [field]: value },
    //         },
    //     });
    // };

    const handleTableChange = (year, field, value) => {
        // Clone formData and update the specific field
        const updatedFormData = { ...formData };
        updatedFormData.premiumClaims[year][field] = value;

        // Validate input (must be a number and not empty)
        let errorMsg = "";
        if (!value) {
            errorMsg = `${field === "premium" ? "Premium" : "Claims"} is required`;
        } else if (isNaN(value) || parseFloat(value) < 0) {
            errorMsg = "Enter a valid positive number";
        }

        // Update error state
        setTableErrors({
            ...tableErrors,
            [year]: {
                ...tableErrors[year],
                [field]: errorMsg,
            },
        });

        // Update state with new form data
        setFormData(updatedFormData);
    };

    const validateForm = () => {
        if (!formData.fname) {
            setFNameError("First Name is required");
        } else {
            setFNameError("");
        }

        if (!formData.lname) {
            setLNameError("Last Name is required");
        } else {
            setLNameError("");
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            setEmailError("Email is required");
        } else if (!emailRegex.test(formData.email)) {
            setEmailError("Enter a valid email address");
        } else {
            setEmailError("");
        }

        const phoneRegex = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
        if (!formData.phone) {
            setPhoneError("Phone is required");
        } else if (!phoneRegex.test(formData.phone)) {
            setPhoneError("Enter a valid phone number");
        } else {
            setPhoneError("");
        }

        let newTableErrors = {};
        lastFiveYears.forEach((year) => {
            let yearErrors = {};

            if (!formData.premiumClaims[year].premium) {
                yearErrors.premium = "Premium is required";
            } else if (isNaN(formData.premiumClaims[year].premium) || parseFloat(formData.premiumClaims[year].premium) < 0) {
                yearErrors.premium = "Enter a valid positive number";
            }

            if (!formData.premiumClaims[year].claims) {
                yearErrors.claims = "Claims are required";
            } else if (isNaN(formData.premiumClaims[year].claims) || parseFloat(formData.premiumClaims[year].claims) < 0) {
                yearErrors.claims = "Enter a valid positive number";
            }
            if (Object.keys(yearErrors).length > 0) {
                newTableErrors[year] = yearErrors;
            }
        });
        setTableErrors(newTableErrors);
    }

    const calculateValues = () => {

        let isValid = true; // Validation flag

        // Validate Name
        if (!formData.fname) {
            setFNameError("First Name is required");
        } else {
            setFNameError("");
        }

        if (!formData.lname) {
            setLNameError("Last Name is required");
        } else {
            setLNameError("");
        }

        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            setEmailError("Email is required");
            isValid = false;
        } else if (!emailRegex.test(formData.email)) {
            setEmailError("Enter a valid email address");
            isValid = false;
        } else {
            setEmailError("");
        }

        // Validate Phone
        const phoneRegex = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
        if (!formData.phone) {
            setPhoneError("Phone is required");
            isValid = false;
        } else if (!phoneRegex.test(formData.phone)) {
            setPhoneError("Enter a valid phone number");
            isValid = false;
        } else {
            setPhoneError("");
        }

        // Validate Table Fields (Premium & Claims)
        let newTableErrors = {};
        lastFiveYears.forEach((year) => {
            let yearErrors = {};

            if (!formData.premiumClaims[year].premium) {
                yearErrors.premium = "Premium is required";
                isValid = false;
            } else if (isNaN(formData.premiumClaims[year].premium) || parseFloat(formData.premiumClaims[year].premium) < 0) {
                yearErrors.premium = "Enter a valid positive number";
                isValid = false;
            }

            if (!formData.premiumClaims[year].claims) {
                yearErrors.claims = "Claims are required";
                isValid = false;
            } else if (isNaN(formData.premiumClaims[year].claims) || parseFloat(formData.premiumClaims[year].claims) < 0) {
                yearErrors.claims = "Enter a valid positive number";
                isValid = false;
            }

            if (Object.keys(yearErrors).length > 0) {
                newTableErrors[year] = yearErrors;
            }
        });

        setTableErrors(newTableErrors);

        // 🚨 Stop calculation if there are errors
        if (!isValid) {
            return;
        }

        if (isValid) {
            const updatedData = {};
            lastFiveYears.forEach((year) => {
                // const premium = parseFloat(formData.premiumClaims[year].premium) || 0;
                // const claims = parseFloat(formData.premiumClaims[year].claims) || 0;
                // const lossFunds = premium * 0.65;
                // // const autoLosses = premium * 0.047619042857142857;
                // const autoLosses = claims / 3;
                // //  claims/3 round off 2 digits
                // const glLosses = claims / 3;
                // const wcLosses = claims / 3;
                // // const totalLosses = autoLosses + glLosses + wcLosses;
                // const totalLosses = claims;
                // // claim add
                // const equity = lossFunds - totalLosses;
                // const netInsuranceSpend = premium - equity;

                const premium = parseFloat(formData?.premiumClaims[year]?.premium) || 0;
                const claims = parseFloat(formData?.premiumClaims[year]?.claims) || 0;
                const lossFunds = parseFloat((premium * 0.65).toFixed(2)); // Ensure it's a number
                const autoLosses = parseFloat((claims / 3).toFixed(2));
                const glLosses = parseFloat((claims / 3).toFixed(2));
                const wcLosses = parseFloat((claims / 3).toFixed(2));
                const totalLosses = claims;
                const equity = parseFloat((lossFunds - totalLosses).toFixed(2));
                const netInsuranceSpend = parseFloat((premium - equity).toFixed(2));

                updatedData[year] = { premium, lossFunds, autoLosses, glLosses, wcLosses, totalLosses, equity, netInsuranceSpend };
            });
            setCalculatedData(updatedData);
        }
    }

    const downloadPDF = () => {
        const doc = new jsPDF({ orientation: 'landscape' }); // Landscape orientation

        // Add logo
        const imgWidth = 70;
        const imgHeight = 22;
        doc.addImage(Logo, "PNG", 10, 10, imgWidth, imgHeight);

        // Add Title
        doc.setFont("Lato", "bold");
        doc.text("5 Year Look Back", 148, 20, { align: "center" }); // Centered in landscape

        // Get the table
        const table = document.getElementById("insurance-table");

        // Add Table
        doc.autoTable({
            html: table,
            startY: 40,
            styles: {
                fontSize: 10,
                textColor: [0, 0, 0],
                overflow: 'linebreak', // Prevent text overflow
            },
            headStyles: {
                fillColor: '#1E2548',
                textColor: '#FFDBBB',
                fontStyle: 'bold',
                halign: 'center'  // Center align table headers
            },
            bodyStyles: {
                halign: 'center'  // Center align table cells
            },
            columnStyles: {
                0: { cellWidth: 'auto' }, // Adjust column 1
                1: { cellWidth: 'auto' }, // Adjust column 2
                2: { cellWidth: 'auto' }, // Adjust column 3
            },
            didParseCell: function (data) {
                // Bold the Last Row
                if (data.row.index === data.table.body.length - 1) {
                    data.cell.styles.fontStyle = "bold";
                    data.cell.styles.textColor = [0, 0, 0];
                }
            }
        });

        // Add Footer
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const footerStartY = pageHeight - 30; // Starting Y position for footer

        // Set Lato Font for Footer
        doc.setFont("Lato", "normal");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);

        // Centered Footer Text with a Line Gap after the First Line
        const footerText = [
            "Net Insurance includes operating costs (reinsurance, claims administration, loss fund dollars, etc.) PLUS claims.",
            "", // Blank line for gap
            "This illustration does not take into affect the interest earned on your loss funds earned prior to distribution, risk sharing with other members of",
            "the captive, and variable costs associated with owning the captive (annual meetings, re-insurance, actuarial costs, etc.)"
        ];

        footerText.forEach((line, index) => {
            const lineHeight = 5; // Adjust line height as needed
            doc.text(line, pageWidth / 2, footerStartY + (index * lineHeight), { align: "center" });
        });

        doc.save("5 Year Look Back.pdf");
    };


    const [customerId, setCustomerId] = useState("");
    const [customerInfo, setCustomerInfo] = useState("");
    const [save, setSave] = useState("no");

    useEffect(() => {
        const fetchCusDetails = async () => {
            const url = process.env.REACT_APP_SERVER_URL + "/captivekings/customerinfo/" + formData.email;
            // const user = JSON.parse(localStorage.getItem("user"));
            const config = {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer ' + user.accessToken
                },
            };

            try {
                const response = await fetch(url, config);
                if (response.ok) {
                    const fetchedData = await response.json();
                    setCustomerInfo(fetchedData); // Update userDetails
                    console.log("Fetched userDetails:", fetchedData); // Debugging fetched data
                } else {
                    console.error('Failed to fetch user details');
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        }
        fetchCusDetails();

    }, [save]);

    const tableRef = useRef(null);

    useEffect(() => {
        if (calculatedData) {
            tableRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [calculatedData]);

    let cusId = null;

    const generateAndSendPDF = async () => {

        // const roles = ["user"];
        // let signupObject = { username: formData.fname + " " + formData.lname, email: formData.email,  password: formData.fname + "@123", role: roles, }
        // const url2 = process.env.REACT_APP_SERVER_URL + "/captivekings/auth/signup";
        // const config2 = {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         // 'Authorization': 'Bearer ' + user.accessToken
        //     },
        //     body: JSON.stringify(signupObject)
        // };
        // try {
        //     const response2 = await fetch(url2, config2);
        //     const data2 = await response2.json();
        //     console.log("data" + data2);
        //     if (response2.ok) {
        //         // cusId = customerInfo.id;
        //         console.log("data" + data2);
        //     }
        // } catch (error) {
        //     console.error('Error:', error);
        // }

        let userObject1 = { firstName: formData.fname, lastName: formData.lname, email: formData.email, phone: formData.phone, company: formData.company }
        // const user = JSON.parse(localStorage.getItem('user'));
        // if (!customerInfo) {
        const doc = new jsPDF({ orientation: 'landscape' });
        const imgWidth = 70;
        const imgHeight = 22;
        doc.addImage(Logo, "PNG", 10, 10, imgWidth, imgHeight);

        // Add Title
        doc.setFont("Lato", "bold");
        doc.text("5 Year Look Back", 148, 20, { align: "center" }); // Centered in landscape

        // Get the table
        const tableElement = document.getElementById("insurance-table");
        if (tableElement) {
            // Add Table
            doc.autoTable({
                html: tableElement,
                startY: 40,
                styles: {
                    fontSize: 10,
                    textColor: [0, 0, 0],
                    overflow: 'linebreak', // Prevent text overflow
                },
                headStyles: {
                    fillColor: '#1E2548',
                    textColor: '#FFDBBB',
                    fontStyle: 'bold',
                    halign: 'center'  // Center align table headers
                },
                bodyStyles: {
                    halign: 'center'  // Center align table cells
                },
                columnStyles: {
                    0: { cellWidth: 'auto' }, // Adjust column 1
                    1: { cellWidth: 'auto' }, // Adjust column 2
                    2: { cellWidth: 'auto' }, // Adjust column 3
                },
                didParseCell: function (data) {
                    // Bold the Last Row
                    if (data.row.index === data.table.body.length - 1) {
                        data.cell.styles.fontStyle = "bold";
                        data.cell.styles.textColor = [0, 0, 0];
                    }
                }

            });
             // Add Footer
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const footerStartY = pageHeight - 30; // Starting Y position for footer

        // Set Lato Font for Footer
        doc.setFont("Lato", "normal");
        doc.setFontSize(10);
        doc.setTextColor(0, 0, 0);

        // Centered Footer Text with a Line Gap after the First Line
        const footerText = [
            "Net Insurance includes operating costs (reinsurance, claims administration, loss fund dollars, etc.) PLUS claims.",
            "", // Blank line for gap
            "This illustration does not take into affect the interest earned on your loss funds earned prior to distribution, risk sharing with other members of",
            "the captive, and variable costs associated with owning the captive (annual meetings, re-insurance, actuarial costs, etc.)"
        ];

        footerText.forEach((line, index) => {
            const lineHeight = 5; // Adjust line height as needed
            doc.text(line, pageWidth / 2, footerStartY + (index * lineHeight), { align: "center" });
        });

        } else {
            console.error("Table not found");
            return;
        }

        const pdfBlob = new Blob([doc.output('blob')], { type: 'application/pdf' });

        // Create FormData and Append PDF Blob
        const formDataObj = new FormData();
        formDataObj.append('pdf', pdfBlob, '5 Year Look Back.pdf');
        formDataObj.append('customerData', JSON.stringify(userObject1));
        // if (customerInfo.length === 0) {
        const url = process.env.REACT_APP_SERVER_URL + "/captivekings/insertcustomer";
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formDataObj  // No need to set headers manually
            });
            const data = await response.json();
            console.log("data", data);
            if (response.ok) {
                cusId = data.id;
                console.log("Customer ID:", cusId);
            }
        } catch (error) {
            console.error('Error:', error);
        }

        const premiumClaimsArray = lastFiveYears?.map((year) => {
            const premium = parseFloat(formData?.premiumClaims[year]?.premium) || 0;
            const claims = parseFloat(formData?.premiumClaims[year]?.claims) || 0;
            const lossFunds = parseFloat((premium * 0.65).toFixed(2)); // Ensure it's a number
            const autoLosses = parseFloat((claims / 3).toFixed(2));
            const glLosses = parseFloat((claims / 3).toFixed(2));
            const wcLosses = parseFloat((claims / 3).toFixed(2));
            const totalLosses = claims;
            const equity = parseFloat((lossFunds - totalLosses).toFixed(2));
            const netInsuranceSpend = parseFloat((premium - equity).toFixed(2));

            return {
                year: year,
                premium: premium,
                claims: claims,
                lossFunds: lossFunds, // Ensure it's a number
                autoLosses: autoLosses,
                glLosses: glLosses,
                wcLosses: wcLosses,
                totalLosses: totalLosses,
                equity: equity,
                netInsuranceSpend: netInsuranceSpend,
                customerId: cusId,
                premiumTotal: 0,
                claimsTotal: 0,
                lossFundsTotal: 0,
                autoLossesTotal: 0,
                glLossesTotal: 0,
                wcLossesTotal: 0,
                totalLossesTotal: 0,
                equityTotal: 0,
            };
        });

        const totals = {
            premiumTotal: 0,
            claimsTotal: 0,
            lossFundsTotal: 0,
            autoLossesTotal: 0,
            glLossesTotal: 0,
            wcLossesTotal: 0,
            totalLossesTotal: 0,
            equityTotal: 0,
            netInsuranceSpendTotal: 0
        };

        premiumClaimsArray.forEach((item) => {
            totals.premiumTotal += item.premium;
            totals.claimsTotal += item.claims;
            totals.lossFundsTotal += item.lossFunds;
            totals.autoLossesTotal += item.autoLosses;
            totals.glLossesTotal += item.glLosses;
            totals.wcLossesTotal += item.wcLosses;
            totals.totalLossesTotal += item.totalLosses;
            totals.equityTotal += item.equity;
        });

        Object.keys(totals).forEach((key) => {
            totals[key] = parseFloat(totals[key].toFixed(2));
        });

        // 🔹 Update all objects in `premiumClaimsArray` with totals
        premiumClaimsArray.forEach((item) => {
            item.premiumTotal = totals.premiumTotal;
            item.claimsTotal = totals.claimsTotal;
            item.lossFundsTotal = totals.lossFundsTotal;
            item.autoLossesTotal = totals.autoLossesTotal;
            item.glLossesTotal = totals.glLossesTotal;
            item.wcLossesTotal = totals.wcLossesTotal;
            item.totalLossesTotal = totals.totalLossesTotal;
            item.equityTotal = totals.equityTotal;
        });

        // Send the array directly, NOT wrapped in an object
        const userObject2 = premiumClaimsArray;
        console.log("Sending request:", JSON.stringify(premiumClaimsArray, null, 2))
        const url1 = process.env.REACT_APP_SERVER_URL + "/captivekings/insertInsuranceDetails/" + formData.email;

        const config1 = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(premiumClaimsArray) // Send array directly
        };

        try {
            const response1 = await fetch(url1, config1);
            const data1 = await response1.json();
            if (response1.ok) {
                console.log("Response:", data1);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        if (calculatedData) {
            generateAndSendPDF();
        }
    }, [calculatedData]);

    const saveData = async (event) => {
        event.preventDefault();
        setSave(prev => prev === "yes" ? "no" : "yes");
        let isValid = true; // Validation flag

        if (!formData.company) {
            setCompanyError("Company is required");
        } else {
            setCompanyError("");
        }

        // Validate Name
        if (!formData.fname) {
            setFNameError("First Name is required");
        } else {
            setFNameError("");
        }

        if (!formData.lname) {
            setLNameError("Last Name is required");
        } else {
            setLNameError("");
        }

        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            setEmailError("Email is required");
            isValid = false;
        } else if (!emailRegex.test(formData.email)) {
            setEmailError("Enter a valid email address");
            isValid = false;
        } else {
            setEmailError("");
        }

        // Validate Phone
        const phoneRegex = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
        if (!formData.phone) {
            setPhoneError("Phone is required");
            isValid = false;
        } else if (!phoneRegex.test(formData.phone)) {
            setPhoneError("Enter a valid phone number");
            isValid = false;
        } else {
            setPhoneError("");
        }

        // Validate Table Fields (Premium & Claims)
        let newTableErrors = {};
        lastFiveYears.forEach((year) => {
            let yearErrors = {};

            if (!formData.premiumClaims[year].premium) {
                yearErrors.premium = "Premium is required";
                isValid = false;
            } else if (isNaN(formData.premiumClaims[year].premium) || parseFloat(formData.premiumClaims[year].premium) < 0) {
                yearErrors.premium = "Enter a valid positive number";
                isValid = false;
            }

            if (!formData.premiumClaims[year].claims) {
                yearErrors.claims = "Claims are required";
                isValid = false;
            } else if (isNaN(formData.premiumClaims[year].claims) || parseFloat(formData.premiumClaims[year].claims) < 0) {
                yearErrors.claims = "Enter a valid positive number";
                isValid = false;
            }

            if (Object.keys(yearErrors).length > 0) {
                newTableErrors[year] = yearErrors;
            }
        });

        setTableErrors(newTableErrors);

        // 🚨 Stop calculation if there are errors
        if (!isValid) {
            return;
        }

        if (isValid) {
            const updatedData = {};
            lastFiveYears.forEach((year) => {
                // const premium = parseFloat(formData.premiumClaims[year].premium) || 0;
                // const claims = parseFloat(formData.premiumClaims[year].claims) || 0;
                // const lossFunds = premium * 0.65;
                // // const autoLosses = premium * 0.047619042857142857;
                // const autoLosses = claims / 3;
                // //  claims/3 round off 2 digits
                // const glLosses = claims / 3;
                // const wcLosses = claims / 3;
                // // const totalLosses = autoLosses + glLosses + wcLosses;
                // const totalLosses = claims;
                // // claim add
                // const equity = lossFunds - totalLosses;
                // const netInsuranceSpend = premium - equity;

                const premium = parseFloat(formData?.premiumClaims[year]?.premium) || 0;
                const claims = parseFloat(formData?.premiumClaims[year]?.claims) || 0;
                const lossFunds = parseFloat((premium * 0.65).toFixed(2)); // Ensure it's a number
                const autoLosses = parseFloat((claims / 3).toFixed(2));
                const glLosses = parseFloat((claims / 3).toFixed(2));
                const wcLosses = parseFloat((claims / 3).toFixed(2));
                const totalLosses = claims;
                const equity = parseFloat((lossFunds - totalLosses).toFixed(2));
                const netInsuranceSpend = parseFloat((premium - equity).toFixed(2));

                updatedData[year] = { premium, lossFunds, autoLosses, glLosses, wcLosses, totalLosses, equity, netInsuranceSpend };
            });
            setCalculatedData(updatedData);



            // } 
            // else {
            //     const url = process.env.REACT_APP_SERVER_URL + "/captivekings/updatecustomer/" + customerInfo[0].id + "/" + formData.email;
            //     const config = {
            //         method: 'POST',
            //         headers: {
            //             'Accept': 'application/json',
            //             'Content-Type': 'application/json',
            //             // 'Authorization': 'Bearer ' + user.accessToken
            //         },
            //         body: JSON.stringify(userObject1)
            //     };
            //     try {
            //         const response = await fetch(url, config);
            //         const data = await response.json();
            //         console.log("data" + data);
            //         if (response.ok) {
            //             // cusId = customerInfo.id;
            //             console.log("data" + data);
            //         }
            //     } catch (error) {
            //         console.error('Error:', error);
            //     }
            // }


            // const premiumClaimsArray = lastFiveYears?.map((year) => {
            //     const premium = parseFloat(formData?.premiumClaims[year]?.premium) || 0;
            //     const claims = parseFloat(formData?.premiumClaims[year]?.claims) || 0;
            //     const lossFunds = parseFloat((premium * 0.65).toFixed(2)); // Ensure it's a number
            //     const autoLosses = parseFloat((claims / 3).toFixed(2));
            //     const glLosses = parseFloat((claims / 3).toFixed(2));
            //     const wcLosses = parseFloat((claims / 3).toFixed(2));
            //     const totalLosses = claims;
            //     const equity = parseFloat((lossFunds - totalLosses).toFixed(2));
            //     const netInsuranceSpend = parseFloat((premium - equity).toFixed(2));

            //     return {
            //         year: year,
            //         premium: premium,
            //         claims: claims,
            //         lossFunds: lossFunds, // Ensure it's a number
            //         autoLosses: autoLosses,
            //         glLosses: glLosses,
            //         wcLosses: wcLosses,
            //         totalLosses: totalLosses,
            //         equity: equity,
            //         netInsuranceSpend: netInsuranceSpend,
            //         customerId: cusId,
            //         premiumTotal: 0,
            //         claimsTotal: 0,
            //         lossFundsTotal: 0,
            //         autoLossesTotal: 0,
            //         glLossesTotal: 0,
            //         wcLossesTotal: 0,
            //         totalLossesTotal: 0,
            //         equityTotal: 0,
            //     };
            // });

            // const totals = {
            //     premiumTotal: 0,
            //     claimsTotal: 0,
            //     lossFundsTotal: 0,
            //     autoLossesTotal: 0,
            //     glLossesTotal: 0,
            //     wcLossesTotal: 0,
            //     totalLossesTotal: 0,
            //     equityTotal: 0,
            //     netInsuranceSpendTotal: 0
            // };

            // premiumClaimsArray.forEach((item) => {
            //     totals.premiumTotal += item.premium;
            //     totals.claimsTotal += item.claims;
            //     totals.lossFundsTotal += item.lossFunds;
            //     totals.autoLossesTotal += item.autoLosses;
            //     totals.glLossesTotal += item.glLosses;
            //     totals.wcLossesTotal += item.wcLosses;
            //     totals.totalLossesTotal += item.totalLosses;
            //     totals.equityTotal += item.equity;
            // });

            // Object.keys(totals).forEach((key) => {
            //     totals[key] = parseFloat(totals[key].toFixed(2));
            // });

            // // 🔹 Update all objects in `premiumClaimsArray` with totals
            // premiumClaimsArray.forEach((item) => {
            //     item.premiumTotal = totals.premiumTotal;
            //     item.claimsTotal = totals.claimsTotal;
            //     item.lossFundsTotal = totals.lossFundsTotal;
            //     item.autoLossesTotal = totals.autoLossesTotal;
            //     item.glLossesTotal = totals.glLossesTotal;
            //     item.wcLossesTotal = totals.wcLossesTotal;
            //     item.totalLossesTotal = totals.totalLossesTotal;
            //     item.equityTotal = totals.equityTotal;
            // });

            // // Send the array directly, NOT wrapped in an object
            // const userObject2 = premiumClaimsArray;
            // console.log("Sending request:", JSON.stringify(premiumClaimsArray, null, 2))
            // const url1 = process.env.REACT_APP_SERVER_URL + "/captivekings/insertInsuranceDetails/" + formData.email;

            // const config1 = {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(premiumClaimsArray) // Send array directly
            // };

            // try {
            //     const response1 = await fetch(url1, config1);
            //     const data1 = await response1.json();
            //     if (response1.ok) {
            //         console.log("Response:", data1);
            //     }
            // } catch (error) {
            //     console.error('Error:', error);
            // }
        }
    }
    const fontFamily = "Lato";

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 5, px: { xs: 2, sm: 4 }, fontFamily }}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <img src={Logo} alt="Logo" style={{ width: "30%", height: "100%" }} />
            </Box><br />
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Typography variant="h6" sx={{ color: '#1E2548', fontFamily: 'Lato', fontSize: '25px', fontWeight: 'bold', p: 1, borderRadius: 1, textAlign: "center" }}>
                    5 Year Look Back</Typography>
            </Box><br />
            <form onSubmit={(e) => { e.preventDefault(); }}>
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4, borderRadius: 2 }}>
                    <Typography variant="h6" sx={{ backgroundColor: "#1E2548", color: '#FFDBBB', fontWeight: 'bold', p: 1, borderRadius: 1, textAlign: "center" }}>
                        Personal Information</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label={<span>Company <span style={{ color: "#1E2548" }}>*</span></span>}
                        name="company"
                        value={formData.company}
                        onChange={handleCompanyChange}
                        error={companyError}
                        helperText={companyError}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label={<span>First Name <span style={{ color: "#1E2548" }}>*</span></span>}
                        name="fname"
                        value={formData.fname}
                        onChange={handleFNameChange}
                        error={fnameError}
                        helperText={fnameError}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label={<span>Last Name <span style={{ color: "#1E2548" }}>*</span></span>}
                        name="lname"
                        value={formData.lname}
                        onChange={handleLNameChange}
                        error={lnameError}
                        helperText={lnameError}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label={<span>Email <span style={{ color: "#1E2548" }}>*</span></span>}
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleEmailChange}
                        error={emailError}
                        helperText={emailError}
                    />
                    <TextField
                        fullWidth
                        margin="normal"
                        label={<span>Phone <span style={{ color: "#1E2548" }}>*</span></span>}
                        name="phone"
                        type="tel"
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        error={phoneError}
                        helperText={phoneError}
                    />
                </Paper>
                <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 4, borderRadius: 2 }}>
                    <Typography variant="h6" sx={{ backgroundColor: "#1E2548", color: '#FFDBBB', fontWeight: 'bold', p: 1, borderRadius: 1, textAlign: "center" }}>
                        Enter Premium And Claims</Typography>
                    <TableContainer component={Paper} elevation={2} sx={{ borderRadius: 2, overflowX: "auto" }}>
                        <Table sx={{ mt: 2 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: '#1E2548', color: '#FFDBBB' }}><b>Year</b></TableCell>
                                    <TableCell sx={{ backgroundColor: '#1E2548', color: '#FFDBBB' }}><b>Total GL, WC, and Auto Premium</b></TableCell>
                                    <TableCell sx={{ backgroundColor: '#1E2548', color: '#FFDBBB' }}><b>Total GL, WC, and Auto Claims</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {lastFiveYears.map((year) => (
                                    <TableRow key={year}>
                                        <TableCell>{year}</TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                label={<span>Total GL, WC, and Auto Premium <span style={{ color: "#1E2548" }}>*</span></span>}
                                                value={formData.premiumClaims[year].premium}
                                                onChange={(e) => handleTableChange(year, "premium", e.target.value)}
                                                error={!!tableErrors[year]?.premium}
                                                helperText={tableErrors[year]?.premium || ""}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                fullWidth
                                                label={<span>Total GL, WC, and Auto Claims <span style={{ color: "#1E2548" }}>*</span></span>}
                                                value={formData.premiumClaims[year].claims}
                                                onChange={(e) => handleTableChange(year, "claims", e.target.value)}
                                                error={!!tableErrors[year]?.claims}
                                                helperText={tableErrors[year]?.claims || ""}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Box textAlign="center" mt={3}>
                    {/* <Button onClick={calculateValues} type="button" variant="contained" sx={{ mt: 3, mr: 2, backgroundColor: "#1E2548", color: "#FFDBBB", borderRadius: "25px", fontWeight: 'bold' }}>Calculate</Button> */}
                    <Button onClick={saveData} type="button" variant="contained" sx={{ mt: 3, mr: 2, backgroundColor: "#1E2548", color: "#FFDBBB", borderRadius: "25px", fontWeight: 'bold' }}>Calculate</Button>
                    {/* <Button type="reset" variant="outlined" color="darkash" sx={{ mt: 3, mr: 2, backgroundColor: "#C0C0C0", borderRadius: "25px", fontWeight: 'bold' }} onClick={() => setFormData({
                        name: "",
                        email: "",
                        phone: "",
                        premiumClaims: lastFiveYears.reduce((acc, year) => { acc[year] = { premium: "", claims: "" }; return acc; }, {})
                    })}>
                        Cancel
                    </Button> */}
                </Box>
            </form>

            {calculatedData && (
                <>
                    <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: 3, mt: 4, borderRadius: 2 }} ref={tableRef}>
                        <Box sx={{ overflowX: "auto", mt: 4, mb: 5 }}>
                            <Typography variant="h6" sx={{ backgroundColor: "#1E2548", color: '#FFDBBB', fontWeight: 'bold', p: 1, borderRadius: 1, textAlign: "center" }}>Calculated Insurance Details</Typography>
                            <TableContainer component={Paper} elevation={2} sx={{ mt: 2, borderRadius: 2 }}>
                                <Table id="insurance-table" sx={{ mt: 2, maxWidth: "lg", border: '1px solid #ddd', borderColor: "black" }}>
                                    <TableHead sx={{ backgroundColor: "#FFDBBB", borderBottom: '1px solid #000', borderColor: "black" }}>
                                        <TableRow>
                                            <TableCell sx={{ border: '1px solid #ddd', borderColor: "black", backgroundColor: 'rgb(181, 186, 194)', textAlign: 'center' }}><b>YEAR</b></TableCell>
                                            <TableCell sx={{ color: ' #6495ED', backgroundColor: 'rgb(215, 234, 240)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black", textAlign: 'center' }}><b>PREMIUM</b></TableCell>
                                            <TableCell sx={{ color: ' #6495ED', backgroundColor: 'rgb(215, 234, 240)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black", textAlign: 'center' }}><b>LOSS FUNDS</b></TableCell>
                                            <TableCell sx={{ color: 'rgb(214, 77, 43)', backgroundColor: 'rgb(215, 234, 240)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black", textAlign: 'center' }}><b>AUTO LOSSES</b></TableCell>
                                            <TableCell sx={{ color: 'rgb(214, 77, 43)', backgroundColor: 'rgb(215, 234, 240)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black", textAlign: 'center' }}><b>GL LOSSES</b></TableCell>
                                            <TableCell sx={{ color: 'rgb(214, 77, 43)', backgroundColor: 'rgb(215, 234, 240)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black", textAlign: 'center' }}><b>WC LOSSES</b></TableCell>
                                            <TableCell sx={{ backgroundColor: 'rgb(235, 201, 193)', color: 'rgb(214, 77, 43)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black", textAlign: 'center' }}><b>TOTAL LOSSES</b></TableCell>
                                            <TableCell sx={{ backgroundColor: 'rgb(220, 241, 220)', color: ' #65a765', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black", textAlign: 'center' }}><b>EQUITY</b></TableCell>
                                            <TableCell sx={{ backgroundColor: 'rgb(211, 174, 202)', border: '1px solid #ddd', fontWeight: 'bold', borderColor: "black", textAlign: 'center' }}><b>NET INSURANCE SPEND</b></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {lastFiveYears.map((year) => (
                                            <TableRow key={year}>
                                                <TableCell sx={{ backgroundColor: 'rgb(181, 186, 194)', border: '1px solid #ddd', borderColor: "black", }}>{year}</TableCell>
                                                <TableCell sx={{ color: ' #6495ED', border: '1px solid #ddd', borderColor: "black" }}> ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculatedData[year].premium)}</TableCell>
                                                <TableCell sx={{ backgroundColor: 'rgb(212, 225, 248)', color: ' #6495ED', border: '1px solid #ddd', borderColor: "black" }}> ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculatedData[year].lossFunds)}
                                                </TableCell>
                                                <TableCell sx={{ color: 'rgb(214, 77, 43)', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculatedData[year].autoLosses)}</TableCell>
                                                <TableCell sx={{ color: 'rgb(214, 77, 43)', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculatedData[year].glLosses)}</TableCell>
                                                <TableCell sx={{ color: 'rgb(214, 77, 43)', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculatedData[year].wcLosses)}</TableCell>
                                                <TableCell sx={{ backgroundColor: 'rgb(235, 201, 193)', color: 'rgb(214, 77, 43)', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculatedData[year].totalLosses)}</TableCell>
                                                <TableCell sx={{ backgroundColor: 'rgb(220, 241, 220)', color: ' #65a765', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculatedData[year].equity)}</TableCell>
                                                <TableCell sx={{ backgroundColor: 'rgb(211, 174, 202)', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(calculatedData[year].netInsuranceSpend)}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell sx={{ border: '1px solid #ddd', borderColor: "black", backgroundColor: 'rgb(181, 186, 194)', }}><b>5 Year Totals</b></TableCell>
                                            <TableCell sx={{ backgroundColor: 'rgb(212, 225, 248)', color: ' #6495ED', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lastFiveYears.reduce((sum, year) => sum + calculatedData[year].premium, 0))}</TableCell>
                                            <TableCell sx={{ backgroundColor: 'rgb(212, 225, 248)', color: ' #6495ED', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lastFiveYears.reduce((sum, year) => sum + calculatedData[year].lossFunds, 0))}</TableCell>
                                            <TableCell sx={{ color: 'rgb(214, 77, 43)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black" }}>${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lastFiveYears.reduce((sum, year) => sum + calculatedData[year].autoLosses, 0))}</TableCell>
                                            <TableCell sx={{ color: 'rgb(214, 77, 43)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black" }}> ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lastFiveYears.reduce((sum, year) => sum + calculatedData[year].glLosses, 0))}</TableCell>
                                            <TableCell sx={{ color: 'rgb(214, 77, 43)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black" }}> ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lastFiveYears.reduce((sum, year) => sum + calculatedData[year].wcLosses, 0))}</TableCell>
                                            <TableCell sx={{ backgroundColor: 'rgb(235, 201, 193)', color: 'rgb(214, 77, 43)', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black" }}> ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lastFiveYears.reduce((sum, year) => sum + calculatedData[year].totalLosses, 0))}</TableCell>
                                            <TableCell sx={{ backgroundColor: 'rgb(241, 245, 186)', color: ' #65a765', fontWeight: 'bold', border: '1px solid #ddd', borderColor: "black" }}> ${new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(lastFiveYears.reduce((sum, year) => sum + calculatedData[year].equity, 0))}</TableCell>
                                            <TableCell sx={{ backgroundColor: 'rgb(211, 174, 202)', border: '1px solid #ddd', borderColor: "black" }}></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box textAlign="center" mt={3}>
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    sx={{ mt: 3, backgroundColor: "#1E2548", color: "#FFDBBB", borderRadius: "25px", fontWeight: 'bold', }}
                                    onClick={downloadPDF}
                                >
                                    Download PDF
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </>

            )
            }
            {/* <Box textAlign="center" mt={4} mb={2}>
                <Typography variant="body2" color="textSecondary">
                    Powered by{' '}
                    <Link
                        href="https://www.chaosology.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="hover"
                        sx={{ color: "#1E2548", fontWeight: "bold" }}
                    >
                        Chaosology
                    </Link>
                </Typography>
            </Box> */}


        </Container >
    );
};

export default InsuranceForm;
