import React, { useState } from 'react';
import { Box, Toolbar, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Drawer, Collapse } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import ExpandMoreIcon
import { useNavigate } from 'react-router-dom';
import Logo from './assets/Magnolia-Logo-T.png';
import CircleIcon from '@mui/icons-material/Circle';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';

const drawerWidth = 250;
const primaryColor = '#1E2548';
const secondaryColor = '#FFDBBB';

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState({}); // State to manage open/close of submenus

    const handleClick = (index) => {
        setOpen((prev) => ({
            ...prev,
            [index]: !prev[index], // Toggle only if it's not already open
        }));
    };

    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
        // {
        //     text: '5 Year Look Back',
        //     icon: <AssignmentIcon />,
        //     path: '/5-year-look-back',
        // },
        {
            text: 'Leads',
            icon: <GroupAddIcon sx={{ color: secondaryColor }} />,
            path: '/',
            submenu: [
                { text: 'Prospect Insights', path: '/dashboard' },
                { text: 'Quote Requests', path: '/dashboard' },
                { text: 'Pending Quotes', path: '/dashboard' },
                {
                    text: 'Quotes Status',
                    path: '/',
                    submenu: [
                        { text: 'Approves Quotes', path: '/dashboard' },
                        { text: 'Declined Quotes', path: '/dashboard' },
                        { text: 'Follow-up Required', path: '/dashboard' },
                    ],
                },
            ],
        },
        {
            text: 'Policies',
            icon: <DescriptionIcon sx={{ color: secondaryColor }} />,
            path: '/',
            submenu: [
                { text: 'Active Policies', path: '/dashboard' },
                { text: 'Expired Policies', path: '/dashboard' },
                { text: 'Claims Management', path: '/dashboard' },
                { text: 'Underwriting', path: '/dashboard' },
                { text: 'Risk Assessment', path: '/dashboard' },

            ],
        },
        {
            text: 'Clients',
            icon: <PeopleIcon sx={{ color: secondaryColor }} />,
            path: '/',
            submenu: [
                { text: 'Customers', path: '/dashboard' },
                { text: 'Agents/Brokers', path: '/dashboard' },
                { text: 'Lead Tracking', path: '/dashboard' },
            ],
        },
        {
            text: 'Finance',
            icon: <AccountBalanceIcon sx={{ color: secondaryColor }} />,
            path: '/',
            submenu: [
                { text: 'Premium Payments', path: '/dashboard' },
                { text: 'Commissions', path: '/dashboard' },
                { text: 'Invoices & Receipts', path: '/dashboard' },
            ],
        },
        {
            text: 'Reports',
            icon: <AssessmentIcon sx={{ color: secondaryColor }} />,
            path: '/',
            submenu: [
                { text: 'Sales Performance', path: '/dashboard' },
                { text: 'Claims Analysis', path: '/dashboard' },
                { text: 'Customer Insights', path: '/dashboard' },
                { text: 'Lead Analytics', path: '/dashboard' },
            ],
        },
        {
            text: 'Tools',
            icon: <BuildIcon sx={{ color: secondaryColor }} />,
            path: '/',
            submenu: [
                {
                    text: '5 year look back',
                    icon: <AssignmentIcon />,
                    path: '/5-year-look-back',
                },
                { text: 'Premium Estimator', path: '/dashboard' },
                { text: 'Risk Analysis Calculator', path: '/dashboard' },
                { text: 'Commission Calculator', path: '/dashboard' },
            ],
        },
        {
            text: 'Settings',
            icon: <SettingsIcon sx={{ color: secondaryColor }} />,
            path: '/',
            submenu: [
                { text: 'User Roles', path: '/dashboard' },
                { text: 'Permissions', path: '/dashboard' },
                { text: 'Templates', path: '/dashboard' },
                { text: 'Logs & Audit', path: '/dashboard' },
            ],
        },
    ];

    const drawer = (
        <Box sx={{
            color: secondaryColor,
            height: '100vh',
            backgroundColor: primaryColor,
            overflowY: 'auto', // Allows the sidebar to be scrollable
            display: 'flex',
            flexDirection: 'column' // Ensures content is stacked vertically inside the sidebar
        }}>
            <Toolbar sx={{ mt: 4 }}>
                <Box display="flex" justifyContent="center">
                    <img
                        src={Logo}
                        alt="Logo"
                        style={{
                            width: '80%',
                            height: 'auto',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            padding: '5px',
                        }}
                    />
                </Box>
            </Toolbar>
            <List>
                {menuItems.map((item, index) => (
                    <div key={index}>
                        {/* Main Menu Item */}
                        <ListItem disablePadding>
                            <ListItemButton
                                onClick={(e) => { e.stopPropagation(); item.submenu ? handleClick(index) : navigate(item.path) }}
                                sx={{ '&:hover': { backgroundColor: '#2C3556' } }}>
                                <ListItemIcon sx={{ color: secondaryColor }}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                                {/* Expand Icon */}
                                {item.submenu && (
                                    <ListItemIcon sx={{ color: secondaryColor }}>
                                        <ExpandMoreIcon
                                            sx={{ transform: open[index] ? 'rotate(180deg)' : 'rotate(0)', transition: 'transform 0.3s' }}
                                        />
                                    </ListItemIcon>
                                )}
                            </ListItemButton>
                        </ListItem>

                        {/* Submenu Items */}
                        {item.submenu && (
                            <Collapse in={open[index]} timeout="auto" unmountOnExit>
                                <List disablePadding>
                                    {item.submenu.map((submenuItem, subIndex) => (
                                        <div key={subIndex}>
                                            <ListItem disablePadding>
                                                <ListItemButton onClick={() => submenuItem.submenu ? handleClick(`${index}-${subIndex}`) : navigate(submenuItem.path)}
                                                    sx={{ pl: 4, '&:hover': { backgroundColor: '#2C3556' } }}>
                                                    <ListItemText primary={submenuItem.text} />
                                                    {/* Expand Icon for Nested Submenu */}
                                                    {submenuItem.submenu && (
                                                        <ListItemIcon sx={{ color: secondaryColor }}>
                                                            <ExpandMoreIcon
                                                                sx={{ transform: open[`${index}-${subIndex}`] ? 'rotate(180deg)' : 'rotate(0)', transition: 'transform 0.3s' }}
                                                            />
                                                        </ListItemIcon>
                                                    )}
                                                </ListItemButton>
                                            </ListItem>

                                            {/* Nested Submenu */}
                                            {submenuItem.submenu && (
                                                <Collapse in={open[`${index}-${subIndex}`]} timeout="auto" unmountOnExit>
                                                    <List disablePadding>
                                                        {submenuItem.submenu.map((subSubmenuItem, subSubIndex) => (
                                                            <ListItem key={subSubIndex} disablePadding>
                                                                <ListItemButton onClick={() => navigate(subSubmenuItem.path)}
                                                                    sx={{ pl: 8, '&:hover': { backgroundColor: '#2C3556' } }}>
                                                                    <ListItemText primary={subSubmenuItem.text} />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </Collapse>
                                            )}
                                        </div>
                                    ))}
                                </List>
                            </Collapse>
                        )}
                    </div>
                ))}
            </List>
        </Box>
    );

    return (
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{
                    display: { xs: 'block', sm: 'block', },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}>
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open>
                {drawer}
            </Drawer>
        </Box>
    );
};

export default Sidebar;
